import { firestore } from '../src/firebase-config';
import { id_firebase } from "./content-site";

export default function XML() {
    firestore.collection("revenda").doc(id_firebase).collection("veiculos")
        .where("veremovido", "==", 0).where("vevendido", "==", 0).orderBy("updated_at", "desc")
        .get()
        .then(querySnapshot => {
            var items = [];
            querySnapshot.forEach(doc => {
                var document = doc.data();
                document.id = doc.id;
                if (document["veexibir_site"] !== false) {
                    items.push(document);
                }
            });

            function escapeXml(unsafe) {
                return unsafe.replace(/[<>&'"]/g, function (c) {
                    switch (c) {
                        case '<': return '&lt;';
                        case '>': return '&gt;';
                        case '&': return '&amp;';
                        case '\'': return '&apos;';
                        case '"': return '&quot;';
                    }
                });
            }

            function listaXML(items) {
                let xmlDoc = document.implementation.createDocument("", "", null);
                let rss = xmlDoc.createElement("rss");
                rss.setAttribute("version", "2.0");
                rss.setAttribute("xmlns:g", "http://base.google.com/ns/1.0");

                let channel = xmlDoc.createElement("channel");

                let title = xmlDoc.createElement("title");
                title.textContent = "Catálogo de Veículos";
                channel.appendChild(title);

                let link = xmlDoc.createElement("link");
                link.textContent = "https://veiculospremiumuberlandia.com.br";
                channel.appendChild(link);

                let description = xmlDoc.createElement("description");
                description.textContent = "Catálogo de veículos para venda";
                channel.appendChild(description);

                items.forEach(item => {
                    let itemElement = xmlDoc.createElement("item");

                    let id = xmlDoc.createElement("g:id");
                    id.textContent = escapeXml(item.id);
                    itemElement.appendChild(id);

                    let title = xmlDoc.createElement("g:title");
                    title.textContent = escapeXml(item.vefipe_name);
                    itemElement.appendChild(title);

                    let description = xmlDoc.createElement("g:description");
                    description.textContent = escapeXml(`Brand: ${item.vefipe_marca},
                                                Model: ${item.vefipe_name},
                                                Km: ${item.vequilometragem},
                                                Fabrication: ${item.veano_fabricacao},
                                                Model year: ${item.vefipe_ano_modelo},
                                                Fuel: ${item.vefipe_combustivel},
                                                Car gearbox: ${item.vecambio},
                                                Color: ${item.vecor},
                                                Doors: ${item.veportas}`);
                    itemElement.appendChild(description);

                    let link = xmlDoc.createElement("g:link");
                    link.textContent = escapeXml(`https://veiculospremiumuberlandia.com.br/#/veiculo/${item.id}`);
                    itemElement.appendChild(link);

                    let image_link = xmlDoc.createElement("g:image_link");
                    image_link.textContent = escapeXml(item.imagens[0].fileUrl);
                    itemElement.appendChild(image_link);
                    
                    let condition = xmlDoc.createElement("g:condition");
                    condition.textContent = item.veveiculonovo === 1 ? 'new' : 'used';
                    itemElement.appendChild(condition);

                    let availability = xmlDoc.createElement("g:availability");
                    availability.textContent = item.vevendido === 0 ? 'in stock' : 'out of stock';
                    itemElement.appendChild(availability);

                    let price = xmlDoc.createElement("g:price");
                    price.textContent = `${item.vevalor} BRL`;
                    itemElement.appendChild(price);

                    let brand = xmlDoc.createElement("g:brand");
                    brand.textContent = escapeXml(item.vefipe_marca);
                    itemElement.appendChild(brand);

                    channel.appendChild(itemElement);
                });

                rss.appendChild(channel);
                xmlDoc.appendChild(rss);

                let serializer = new XMLSerializer();
                let xmlString = `<?xml version="1.0" encoding="UTF-8"?>\n` + serializer.serializeToString(xmlDoc);
                return xmlString;
            }

            function saveFile(nomeArquivo, conteudo) {
                const blob = new Blob([conteudo], { type: "application/xml" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = nomeArquivo;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

            const xmlString = listaXML(items);
            saveFile("estoque.xml", xmlString);
            localStorage.setItem('xmlDownloaded', true);
        });
}
