import { Component } from "react";
import React from 'react';
import { id_firebase } from '../content-site';
import { firestore } from '../firebase-config';
import XML from '../xmlFile';

class XmlPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            veiculos: [],
            veiculosComplete: [],
            countVeiculos: 0,
            instagramData: [],
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
            revenda: {},
            bannerData: [],
            veiculoSelecionado: null,
            xmlDownloaded: false,
        };
        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.mounted = true;

        firestore.collection("revenda").doc(id_firebase)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.setState({
                        revenda: doc.data(),
                    });
                } else {
                    this.setState({
                        revenda: {
                            ativo: 0,
                        }
                    });
                }
            }).catch(function (error) {
                console.error("Erro ao obter revenda:", error);
            });

        firestore.collection("revenda").doc(id_firebase).collection("veiculos").where("veremovido", "==", 0).where("vevendido", "==", 0).orderBy("updated_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    if (document["veexibir_site"] !== false) {
                        items.push(document);
                    }
                });
                if (this.mounted) {
                    this.setState({
                        veiculos: items,
                        veiculosComplete: items,
                        countVeiculos: items.length
                    }, () => {
                        if (!this.state.xmlDownloaded) {
                            XML();
                            this.setState({ xmlDownloaded: true });
                        }
                    });
                }
            });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        let filtro = this.state.buscaVeiculo.toLowerCase();
        let tipoVeiculo = this.state.tipoVeiculo;

        let veiculosFilter = this.state.veiculosComplete.filter(function (veiculo) {
            let descricaoVeiculo = veiculo.vefipe_marca.toLowerCase() + " " + veiculo.vefipe_name.toLowerCase();
            if (tipoVeiculo === 'novos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem <= 100;
            }
            if (tipoVeiculo === 'seminovos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem > 100;
            }
            return descricaoVeiculo.includes(filtro);
        });

        this.setState({
            veiculos: veiculosFilter
        });

    }

    handleClick(event) {
        this.setState({
            veiculos: this.state.veiculosComplete,
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
        });
    }

    render(){
        return(
            <div></div>
        )
    }
}

export default XmlPage;
